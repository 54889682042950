export const TOKEN = "token";
export const LOGOUT = "logout";
export const USER_INFO = "userInfo";
export const SHOP_INFO = "shopInfo";
export const CONFIRM_FOOTBALL = "confirmFootball";
export const CONFIRM_BEIJING = "confirmBeijing";
export const CONFIRM_BASKETBALL = "confirmBasketball";
export const CONFIRM_TRADITION = "confirmTradition";
export const CONFIRM_SUPER_LOT = "confirmSuperLot";
export const CONFIRM_SEVEN_STAR = "confirmSevenStar";
export const CONFIRM_RANK_THREE = "confirmRankThree";
export const CONFIRM_RANK_FIVE = "confirmRankFive";
export const WITHDRAW_DETAIL = "withdrawDetail";
export const SUB_USER_DETAIL = "subUserDetail";
export const FOOTBALL_MATCH_LIST = "footballMatchList";
export const BASKETBALL_MATCH_LIST = "basketballMatchList";
export const BEIJING_MATCH_LIST = "beijingMatchList";
export const FOLLOW_BET_DATA = "followBetData";
export const FOLLOW_ORDER = "followOrder";
export const RECHARGE_CHANNEL = "rechargeChannel";
export const RECHARGE_FORM = "rechargeForm";
export const CURRENT_CHAT_ITEM = "currentChatItem";
export const UNREAD_MSG_COUNT = "unReadMsgCount";
export const CURRENT_GROUP_ITEM = "currentGroupItem";
export const FRIEND_INFO = "friendInfo";
export const GROUP_MEMBER_LIST = "groupMemberList";
export const ALL_CHAT_LIST = "allChatList";
export const ALL_GROUP_LIST = "allGroupList";
export const ALL_FRIEND_LIST = "allFriendList";