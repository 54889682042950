import Store from "@/store/store";
import * as types from "@/store/types";

export default {
    appPlatform: "XB", // XB SP
    appTheme: 2,
    setBackground() {
        if (this.appTheme === 1) {
            document.body.setAttribute("style", "background-color: #F0EFF5;");
        } else if (this.appTheme === 2) {
            document.body.setAttribute("style", "background-color: #F0EFF5;");
        }
    },
    initStorage: function () {
        Store.commit(types.TOKEN, localStorage.getItem(types.TOKEN));

        this.loadStorage(types.USER_INFO);
        this.loadStorage(types.SHOP_INFO);
        this.loadStorage(types.CONFIRM_FOOTBALL);
        this.loadStorage(types.CONFIRM_TRADITION);
        this.loadStorage(types.CONFIRM_BEIJING);
        this.loadStorage(types.CONFIRM_BASKETBALL);
        this.loadStorage(types.CONFIRM_SUPER_LOT);
        this.loadStorage(types.CONFIRM_SEVEN_STAR);
        this.loadStorage(types.CONFIRM_RANK_THREE);
        this.loadStorage(types.CONFIRM_RANK_FIVE);
        this.loadStorage(types.WITHDRAW_DETAIL);
        this.loadStorage(types.SUB_USER_DETAIL);
        this.loadStorage(types.FOOTBALL_MATCH_LIST);
        this.loadStorage(types.BASKETBALL_MATCH_LIST);
        this.loadStorage(types.BEIJING_MATCH_LIST);
        this.loadStorage(types.FOLLOW_BET_DATA);
        this.loadStorage(types.FOLLOW_ORDER);
        this.loadStorage(types.RECHARGE_CHANNEL);
        this.loadData(types.RECHARGE_FORM);
        this.loadStorage(types.CURRENT_CHAT_ITEM);
        this.loadData(types.UNREAD_MSG_COUNT);
        this.loadStorage(types.CURRENT_GROUP_ITEM);
        this.loadStorage(types.FRIEND_INFO);
        this.loadStorage(types.GROUP_MEMBER_LIST);
        this.loadStorage(types.ALL_CHAT_LIST);
        this.loadStorage(types.ALL_GROUP_LIST);
        this.loadStorage(types.ALL_FRIEND_LIST);
    },

    clearStorage: function () {
        Store.commit(types.USER_INFO, {});
        Store.commit(types.SHOP_INFO, {});
        Store.commit(types.TOKEN, null);
        Store.commit(types.CONFIRM_FOOTBALL, null);
        Store.commit(types.CONFIRM_TRADITION, null);
        Store.commit(types.CONFIRM_BASKETBALL, null);
        Store.commit(types.CONFIRM_BEIJING, null);
        Store.commit(types.CONFIRM_SUPER_LOT, null);
        Store.commit(types.CONFIRM_SEVEN_STAR, null);
        Store.commit(types.CONFIRM_RANK_THREE, null);
        Store.commit(types.CONFIRM_RANK_FIVE, null);
        Store.commit(types.WITHDRAW_DETAIL, null);
        Store.commit(types.SUB_USER_DETAIL, null);
        Store.commit(types.FOOTBALL_MATCH_LIST, []);
        Store.commit(types.BASKETBALL_MATCH_LIST, []);
        Store.commit(types.BEIJING_MATCH_LIST, []);
        Store.commit(types.FOLLOW_BET_DATA, {});
        Store.commit(types.RECHARGE_CHANNEL, []);
        Store.commit(types.FRIEND_INFO, {});
        Store.commit(types.ALL_CHAT_LIST, []);
        Store.commit(types.ALL_GROUP_LIST, []);
        Store.commit(types.GROUP_MEMBER_LIST, []);
        Store.commit(types.ALL_FRIEND_LIST, []);
    },

    loadStorage(typeName) {
        let infoJson = sessionStorage.getItem(typeName);
        if (infoJson && infoJson !== "undefined") {
            Store.commit(typeName, JSON.parse(infoJson));
        }
    },

    loadData(typeName) {
        let infoJson = sessionStorage.getItem(typeName);
        if (infoJson && infoJson !== "undefined") {
            Store.commit(typeName, infoJson);
        }
    },
}
